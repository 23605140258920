@import url('./app.css');
#root, body, html {
    height: inherit !important;
}

body {
    overflow-y: auto !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, .font-manul {
    font-family: "Inter", sans-serif;
}

.form-control, .form-label {
    font-family: "Inter", sans-serif;
}

.avatar-text-wrap {
    height: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #dee2e6;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #222e3c;
}

.drop_menu {
    inset: 37px auto auto auto !important;
    margin: unset !important;
    transform: unset !important;
    right: 0px !important;
}

.dropdown-item svg {
    stroke-width: 2;
    height: 18px;
    width: 18px;
    margin-right: 0.25rem;
    vertical-align: middle;
}

.sidebar-item .sidebar-link.active:hover, .sidebar-item>.sidebar-link.active {
    background: linear-gradient(90deg, rgba(59, 125, 221, .1), rgba(59, 125, 221, .0875) 50%, transparent);
    border-left-color: #3b7ddd;
    color: #e9ecef;
}

.sidebar-item .sidebar-link.active:hover, .sidebar-item>.sidebar-link.active svg {
    color: #e9ecef;
}

.react-bs-table-sizePerPage-dropdown button {
    background: transparent;
    border-color: #dee2e6;
    color: #495057;
    font-family: "Inter", sans-serif;
}

.react-bs-table-sizePerPage-dropdown button:hover, .react-bs-table-sizePerPage-dropdown button:focus {
    background: rgba(0, 0, 0, 0.0375) !important;
    border-color: #dee2e6 !important;
    outline: 0 !important;
    box-shadow: unset !important;
    color: #495057 !important;
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a[role="menuitem"] {
    width: 100%;
    display: block;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}

.table-wrap-header {
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.table-search {
    width: 100%;
    max-width: 250px;
}

.table thead tr th, .table tbody tr td {
    font-family: "Inter", sans-serif;
}

.search-form {
    width: 100%;
    position: relative;
}

.search-form .form-control {
    padding-left: 35px;
    font-family: "Inter", sans-serif;
}

.search-form button {
    background: transparent;
    border: 0;
    position: absolute;
    top: 4.5px;
    left: 3px;
    z-index: 1;
}

.table-wrap-footer {
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-wrap-footer .react-bootstrap-table-pagination-total {
    font-family: "Inter", sans-serif;
    font-size: 13px;
}

.table-wrap-footer .pagination {
    margin-bottom: 0;
}

.table-wrap-footer .pagination .page-item .page-link {
    color: #222e3c;
    border: 0;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 5px;
    margin: 0 5px;
    background: transparent;
    text-decoration: none;
    font-size: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    font-family: "Inter", sans-serif;
}

.table-wrap-footer .pagination .page-item:last-child .page-link, .table-wrap-footer .pagination .page-item:first-child .page-link {
    margin: 0;
}

.table-wrap-footer .pagination .page-item .page-link:hover, .table-wrap-footer .pagination .page-item .page-link:focus {
    outline: 0;
    box-shadow: unset;
}

.table-wrap-footer .pagination .page-item .page-link:hover {
    background: #dee2e6;
}

.table-wrap-footer .pagination .page-item.active .page-link {
    background: #222e3c;
    color: #FFF;
}

.empty-table {
    width: 100%;
    /* text-align: center; */
}

.empty-table p {
    color: #222e3c;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    /* text-align: center; */
}

/* submenu */

.submenu {
    padding-left: 60px;
    margin-bottom: 0;
    background: #222e3c;
    padding: 0px;
    list-style: none;
}

/* submenu over */

.error {
    border-color: #dc3545;
}

.small-loader {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #000;
    border-bottom-color: transparent;
    animation: loader 0.3s linear infinite;
}

.small-loader.white {
    border-color: #FFF;
    border-bottom-color: transparent;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width:576px) {
    .drop_menu {
        inset: initial !important;
        right: 10px !important;
        margin: unset !important;
        transform: unset !important;
    }
}

/* simple table */

.bg-table-light {
    /* background: #dee2e6; */
    background: #f3f3f3;
}

table tbody tr td.expand-cell, table thead tr th.expand-cell-header {
    text-align: center;
}

table thead tr th.expand-cell-header .expand-table-button, table tbody tr td.expand-cell .expand-table-button {
    cursor: pointer;
    font-size: 15px;
    color: #495057;
}

table thead tr th.expand-cell-header .expand-table-button.active, table tbody tr td.expand-cell .expand-table-button.active {
    font-weight: 500;
    color: #000;
}

/* simple table over */

.legend {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    margin-left: 15%;
    /* margin-bottom: 15px; */
}

.legend-item {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    align-items: center;
}

.legend-item .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.analysis-buttons {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    width: fit-content;
    margin: auto;
}

.analysis-buttons>button:first-child {
    border-radius: 2rem 0 0 2rem;
}

.analysis-buttons>button:last-child {
    border-radius: 0 2rem 2rem 0;
}

.analysis-buttons .analysis-button {
    /* padding-left: 2rem;
    padding-right: 2rem; */
    border: none;
    background-color: #f6f7fb;
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-weight: 400;
    padding: 3px 13px;
}

.analysis-buttons .analysis-button::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #534cdd;
    display: none;
    border-radius: 2rem;
    z-index: -1;
}

.analysis-buttons .analysis-button.active {
    color: #FFF;
}

.analysis-buttons .analysis-button.active::after {
    display: inline-block;
}

/* .analysis-buttons .analysis-button.active {
    /* border-radius: 2rem; */

/* margin-left: -1rem;
    margin-right: -1rem; 
    background-color: #534cdd;
    color: white;
    z-index: 2;
} */

/* sub nav */

.sub-navigation {
    list-style: none;
    padding-left: 0;
    width: 100%;
    display: inline-block;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0375);
}

.sub-navigation li {
    display: inline-block;
}

.sub-navigation li a {
    padding: 5px 15px;
    display: inline-block;
    position: relative;
    color: #495057;
    text-decoration: none;
    font-weight: 500;
}

.sub-navigation li a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    /* border:2px solid #fcaf17; */
    /* display: none; */
    width: 0%;
    height: 2px;
    background: #fcaf17;
    transition: width 0.3s linear;
}

.sub-navigation li a.active {
    color: #fcaf17;
    font-weight: 500;
}

.sub-navigation li a.active::after, .sub-navigation li a:hover::after {
    width: 100%;
}

.sub-navigation li a:hover {
    color: #fcaf17;
    font-weight: 500;
}

/*.sub-navigation li a:hover::after{
    background: #495057; */

/* } */

/* sub nav over */

/* date Range */

.date-range-btn {
    background: transparent;
    border: 1px solid #939ba2;
    border-radius: 3px;
    padding: 3px 10px;
}
.date-range-btn .icon{
    margin-left: 10px;
}
.daterangepicker{
    right: 65px !important;
}
.daterangepicker::before{
    left: auto !important;
    right: 9px;
}

/* date Range over */
/* phone input */
.phone-input{
    width: 100%;
    display: flex;
    border: 1px solid #ced4da;
    color: #495057;
    font-weight: 400;
    line-height: 1.5;
    font-size: .875rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    border-radius: .2rem;

}
.phone-input .flag-container{
    position: relative;
    display: inline-block;
    width: 85px !important;
    right: 0 !important;
}
.phone-input .PhoneInputCountry{
    padding-left: 10px;
}
.phone-input .PhoneInputCountry .PhoneInputCountryIcon{
    height: 32px;
    border: 0;
    background: transparent;
    box-shadow: unset;
    line-height: unset;
    display: flex;
    align-items: center;
}
.phone-input .flag-container .selected-flag{
    display: flex !important;
    padding-left: 5px;
    align-items: center;
    justify-content: center;
}
.phone-input .flag-container .selected-flag .selected-dial-code{
    padding-left: 3px !important;
}
.phone-input input[type="tel"]{
    border: 0;
    padding: 0.3rem 15px 0.3rem 10px !important;
    width: 100%;
}
.phone-input input[type="tel"]:hover,
.phone-input input[type="tel"]:focus{
    outline: 0;
    box-shadow: unset;
}
/* phone input over */